<template>
  <div v-if="$getBeta()" class="beta">
    <v-data-table-server
      density="compact"
      :loading="loading"
      :items-length="totalElements"
      :items="flattenedTree"
      item-value="uniqueId"
      :hide-default-footer="true"
      :headers="fields"
      :fields="fields"
      disable-pagination
      hover
    >
      <template v-slot:[`item.type`]="{ item }">
        <span v-for="index in item.level" :key="index"> &nbsp; </span>
        <router-link
          v-if="item.type == 'Farm'"
          :to="{
            name: 'masterdata_farms_edit',
            params: { id: `${item.id}`, tab: 'overview' }
          }"
          ><v-icon>mdi-home-variant-outline</v-icon></router-link
        >
        <router-link
          v-if="item.type == 'Dairy'"
          :to="{
            name: 'masterdata_dairies_edit',
            params: { id: `${item.id}`, tab: 'overview' }
          }"
        >
          <v-icon>mdi-factory</v-icon></router-link
        >

        <router-link
          v-if="item.type == 'LegalEntity'"
          :to="{
            name: 'masterdata_legal_entities_edit',
            params: { id: `${item.id}`, tab: 'overview' }
          }"
          ><v-icon>mdi-scale-balance</v-icon></router-link
        >

        <router-link v-if="item.type == 'User'" :to="{ name: 'applicationUsers_edit', params: { id: `${item.id}` } }"><v-icon>mdi-account</v-icon></router-link>
      </template>
      <template v-slot:[`item.validFrom`]="{ item }">
        <template v-if="item.validFrom">
          {{ $formatChDate(item.validFrom) }}
        </template>
      </template>
      <template v-slot:[`item.validUntil`]="{ item }">
        <template v-if="item.validUntil">
          {{ $formatChDate(item.validUntil) }}
        </template>
      </template>
    </v-data-table-server>
  </div>
</template>

<script lang="ts">
import { flattenHierarchyTree } from '@/utils/TreeUtils'
import { apiURL } from '@/main'
import { showError } from '@/services/axios'
import { DTSHeader } from '@/services/BackendService'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Hierarchy',
  props: {
    type: { type: String, default: 'Farm' },
    id: { type: String, default: 1 }
  },
  data() {
    return {
      open: [],
      active: [],
      visibleItems: [],
      totalElements: 0,
      items: [],
      fields: <DTSHeader[]>[
        // Typ
        {
          title: this.$t('hierarchy_table_header_type'),
          key: 'type',
          sortable: false,
          align: 'left'
        },
        // Agate-Nr.
        {
          title: this.$t('hierarchy_table_header_agate_nr'),
          key: 'agateId',
          sortable: false,
          align: 'left'
        },
        // AgisId
        {
          title: this.$t('hierarchy_table_header_agis_id'),
          key: 'agisId',
          sortable: false,
          align: 'left'
        },
        // Info
        {
          title: this.$t('hierarchy_table_header_info'),
          key: 'name',
          sortable: false,
          align: 'left'
        },
        // UID
        {
          title: this.$t('hierarchy_table_header_uid'),
          key: 'uid',
          sortable: false,
          align: 'left'
        },
        // BUR
        {
          title: this.$t('hierarchy_table_header_ber_no'),
          key: 'berNo',
          sortable: false,
          align: 'left'
        },
        // Datum bewirtschaftet
        {
          title: this.$t('hierarchy_table_header_management_date'),
          key: 'validFrom',
          sortable: false,
          align: 'left'
        },
        // Datum angehörig
        {
          title: this.$t('hierarchy_table_header_belonging_date'),
          key: 'validUntil',
          sortable: false,
          align: 'left'
        },
        // Beschreibung
        {
          title: this.$t('hierarchy_table_header_description'),
          key: 'typeName',
          sortable: false,
          align: 'left'
        }
      ],
      loaders: 0, // TODO when loading
      flattenedTree: []
    }
  },
  computed: {
    loading() {
      return this.loaders > 0 ? true : false
    }
  },
  methods: {
    buildNow() {
      this.flattenedTree = []
      this.flattenedTree = flattenHierarchyTree(this.items)
    },
    setUniqueKey(item) {
      item.uniqueId = `type${item.type}id${item.id}level${item.level}validFrom${item.validFrom}`
      item.uniqueKey = `type${item.type}id${item.id}validFrom${item.validFrom}`
    },
    async loadfarmChildren(farm, visibleItems, level) {
      try {
        this.loaders++
        const result = await this.axios.get(apiURL + '/farms/' + farm.id + '/managements')
        if (await result) {
          var legalEntities = result.data
          legalEntities.forEach((element, index) => {
            const treeElement = {
              type: 'LegalEntity',
              typeName: element['typeName' + this.$getUpLang()],
              name: element.legalEntityAddressName1 + ' ' + element.legalEntityAddressName2,
              id: element.legalEntityId,
              agateId: '',
              agisId: element.legalEntityAgisId,
              uid: element.legalEntityUid,
              berNo: element.localUnitBerNo,
              open: null,
              validFrom: element.validFrom,
              validUntil: element.validUntil,
              level: level,
              children: []
            }
            this.setUniqueKey(treeElement)
            farm.children.splice(0, 0, treeElement)

            if (visibleItems.includes(treeElement.uniqueKey)) {
              // do nothing
            } else {
              this.buildTree(treeElement, visibleItems, level + 1)
            }
          })
        }
      } catch (e) {
        // showError(e)
        // console.log('e', e)
      } finally {
        this.loaders--
      }
      try {
        this.loaders++
        const result2 = await this.axios.get(apiURL + '/farms/' + farm.id + '/children')
        if (await result2) {
          var farms = result2.data
          farms.forEach((element) => {
            const treeElement = {
              type: 'Farm',
              typeName: element['type' + this.$getUpLang()],
              name: element.legalEntityAddressName1 + ' ' + element.legalEntityAddressName2,
              id: element.localUnitId,
              agateId: '',
              agisId: element.legalEntityAgisId,
              uid: element.legalEntityUid,
              berNo: element.localUnitBerNo,
              open: null,
              validFrom: element.validFrom,
              validUntil: element.validUntil,
              level: level,
              children: []
            }
            this.setUniqueKey(treeElement)
            farm.children.splice(0, 0, treeElement)
            if (visibleItems.includes(treeElement.uniqueKey)) {
              // do nothing
            } else {
              this.buildTree(treeElement, visibleItems, level + 1)
            }
          })
        }
      } catch (e2) {
      } finally {
        this.loaders--
      }
      try {
        this.loaders++
        const result3 = await this.axios.get(apiURL + '/farms/' + farm.id + '/parent')
        if (await result3) {
          var farms = await result3.data
          farms.forEach((element) => {
            const treeElement = {
              type: 'Farm',
              typeName: element['type' + this.$getUpLang()],
              name: element.legalEntityAddressName1 + ' ' + element.legalEntityAddressName2,
              id: element.localUnitId,
              agateId: '',
              agisId: element.legalEntityAgisId,
              uid: element.legalEntityUid,
              berNo: element.localUnitBerNo,
              open: null,
              validFrom: element.validFrom,
              validUntil: element.validUntil,
              level: level,
              children: []
            }
            this.setUniqueKey(treeElement)
            farm.children.splice(0, 0, treeElement)

            if (visibleItems.includes(treeElement.uniqueKey)) {
              // do nothing
            } else {
              this.buildTree(treeElement, visibleItems, level + 1)
            }
          })
        }
      } catch (e3) {
      } finally {
        this.loaders--
      }
    },
    async loadDairyChildren(dairy, visibleItems, level) {
      try {
        this.loaders++
        const result = await this.axios.get(apiURL + '/dairies/' + dairy.id + '/managements')
        if (await result) {
          var legalEntities = result.data
          legalEntities.forEach((element, index) => {
            const treeElement = {
              type: 'LegalEntity',
              typeName: element['typeName' + this.$getUpLang()],
              name: element.legalEntityAddressName1 + ' ' + element.legalEntityAddressName2,
              id: element.legalEntityId,
              agateId: '',
              agisId: element.legalEntityAgisId,
              uid: element.legalEntityUid,
              berNo: element.localUnitBerNo,
              open: null,
              validFrom: element.validFrom,
              validUntil: element.validUntil,
              level: level,
              children: []
            }
            this.setUniqueKey(treeElement)
            dairy.children.splice(0, 0, treeElement)

            if (visibleItems.includes(treeElement.uniqueKey)) {
              // do nothing
            } else {
              this.buildTree(treeElement, visibleItems, level + 1)
            }
          })
        }
      } catch (e) {
        // showError(e)
        // console.log('e', e)
      } finally {
        this.loaders--
      }
    },
    async loadLegalEntityChildren(legalEntity, visibleItems, level) {
      try {
        this.loaders++
        const result = await this.axios.get(apiURL + '/legalEntities/' + legalEntity.id + '/managements')
        if (await result) {
          var farms = result.data
          farms.forEach((element) => {
            var treeElement = {
              type: ['Verwerter', 'Sömmerungsverwerter'].includes(element.typeNameDe) ? 'Dairy' : 'Farm',
              typeName: element['typeName' + this.$getUpLang()],
              name: element.legalEntityAddressName1 + ' ' + element.legalEntityAddressName2,
              id: element.localUnitId,
              agateId: '',
              agisId: element.legalEntityAgisId,
              uid: element.legalEntityUid,
              berNo: element.localUnitBerNo,
              open: null,
              validFrom: element.validFrom,
              validUntil: element.validUntil,
              level: level,
              children: []
            }
            this.setUniqueKey(treeElement)
            legalEntity.children.splice(0, 0, treeElement)

            if (visibleItems.includes(treeElement.uniqueKey)) {
              // do nothing
            } else {
              this.buildTree(treeElement, visibleItems, level + 1)
            }
          })
        }
      } catch (e) {
        // showError(e)
        // console.log('e', e)
      } finally {
        this.loaders--
      }

      try {
        this.loaders++
        const result2 = await this.axios.get(apiURL + '/applicationUsers/findByLegalEntityId', {
          params: {
            legalEntityId: legalEntity.id
          }
        })
        if (await result2) {
          var users = result2.data
          users.forEach((element) => {
            var treeElement = {
              type: 'User',
              typeName: '',
              name: element.name,
              id: element.id,
              agateId: element.agateId,
              agisId: '',
              uid: '',
              berNo: '',
              open: null,
              validFrom: element.validFrom,
              validUntil: element.validUntil,
              level: level,
              children: []
            }
            this.setUniqueKey(treeElement)
            legalEntity.children.splice(0, 0, treeElement)
            if (visibleItems.includes(treeElement.uniqueKey)) {
              // do nothing
            } else {
              this.buildTree(treeElement, visibleItems, level + 1)
            }
          })
        }
      } catch (e2) {
      } finally {
        this.loaders--
      }
    },
    async loadApplicationUserChildren(applicationUser, visibleItems, level) {
      try {
        this.loaders++
        const result = await this.axios.get(apiURL + '/applicationUsers/' + applicationUser.id + '/applicationUserLegalEntities')
        if (await result) {
          var legalEntities = await result.data
          if (await legalEntities) {
            legalEntities.forEach((element) => {
              var treeElement = {
                type: 'LegalEntity',
                typeName: element['type' + this.$getUpLang()],
                name: element.legalEntityAddressName,
                id: element.legalEntityId,
                agisId: element.legalEntityAgisId,
                agateId: '',
                uid: element.legalEntityUid,
                berNo: element.localUnitBerNo,
                open: null,
                validFrom: element.validFrom,
                validUntil: element.validUntil,
                level: level,
                children: []
              }
              this.setUniqueKey(treeElement)
              applicationUser.children.splice(0, 0, treeElement)
              if (visibleItems.includes(treeElement.uniqueKey)) {
                // do nothing
              } else {
                this.buildTree(treeElement, visibleItems, level + 1)
              }
            })
          }
        }
      } catch (e2) {
      } finally {
        this.loaders--
      }
    },
    async loadTreeRoot() {
      switch (this.type) {
        case 'Farm':
          try {
            this.loaders++
            const result = await this.axios.get(apiURL + '/farms/' + this.id)
            if (await result) {
              var farm = result.data
              return {
                type: 'Farm',
                typeName: farm.farmType[this.$getLangKey()],
                name: farm.name,
                id: farm.id,
                agateId: '',
                agisId: farm.agisId,
                uid: farm.legalEntityUid,
                berNo: farm.localUnitBerNo,
                open: null,
                validFrom: farm.validFrom,
                validUntil: farm.validUntil,
                level: 0,
                children: []
              }
            }
          } catch (e3) {
            showError(e3)
          } finally {
            this.loaders--
          }
          break
        case 'LegalEntity':
          try {
            this.loaders++
            const result = await this.axios.get(apiURL + '/legalEntities/' + this.id)
            if (await result) {
              var legalEntity = result.data
              return {
                type: 'LegalEntity',
                typeName: legalEntity['legalEntityType' + this.$getUpLang()],
                name: legalEntity.defaultAddress.name1 + ' ' + legalEntity.defaultAddress.name2,
                id: legalEntity.id,
                agisId: legalEntity.agisId,
                agateId: '',
                uid: legalEntity.legalEntityUid,
                berNo: legalEntity.localUnitBerNo,
                open: null,
                validFrom: legalEntity.validFrom,
                validUntil: legalEntity.validUntil,
                level: 0,
                children: []
              }
            }
          } catch (e3) {
          } finally {
            this.loaders--
          }
          break
        case 'User':
          try {
            this.loaders++
            const result = await this.axios.get(apiURL + '/applicationUsers/' + this.id)
            if (await result) {
              var user = result.data
              return {
                type: 'User',
                typeName: '',
                name: user.name,
                id: user.id,
                agateId: user.agateId,
                agisId: '',
                uid: '',
                berNo: '',
                open: null,
                validFrom: user.validFrom,
                validUntil: user.validUntil,
                level: 0,
                children: []
              }
            }
          } catch (e3) {
            //showError(e3)
          } finally {
            this.loaders--
          }
          break
        case 'Dairy':
          try {
            this.loaders++
            const result = await this.axios.get(apiURL + '/dairies/' + this.id)
            if (await result) {
              var dairy = result.data
              return {
                type: 'Dairy',
                typeName: dairy.dairyType,
                name: dairy.formattedName,
                id: dairy.id,
                agateId: '',
                agisId: dairy.agisId,
                uid: '',
                berNo: dairy.berNo,
                open: null,
                validFrom: '',
                validUntil: '',
                level: 0,
                children: []
              }
            }
          } catch (e3) {
            showError(e3)
          } finally {
            this.loaders--
          }
          break
      }
    },
    async buildTree(parentNode, visibleItems, level) {
      if (level > 7) return

      visibleItems.push(parentNode.uniqueKey)
      parentNode.level = level
      switch (parentNode.type) {
        case 'Farm': {
          this.loadfarmChildren(parentNode, visibleItems, level + 1)
          break
        }
        case 'LegalEntity': {
          this.loadLegalEntityChildren(parentNode, visibleItems, level + 1)
          break
        }
        case 'User': {
          this.loadApplicationUserChildren(parentNode, visibleItems, level + 1)
          break
        }
        case 'Dairy': {
          this.loadDairyChildren(parentNode, visibleItems, level + 1)
          break
        }
      }
    }
  },
  watch: {
    items: {
      handler(newValue) {
        this.flattenedTree = flattenHierarchyTree(newValue)
      },
      deep: true
    }
  },
  async mounted() {
    let startNode = await this.loadTreeRoot()
    this.setUniqueKey(await startNode)
    this.items.splice(0, 0, await startNode)
    this.buildTree(await startNode, this.visibleItems, 1)
  }
})
</script>
